.group-item {
    align-items: center;
    height: 50px;
    display: flex;
}

.group-description div.MuiTextField-root {
    margin: 0;
}

/* .create-ticket .loader {
    width: 20px;
}

.create-ticket .loader-container {
    width: inherit;
    height: inherit;
} */

button.create-group-btn.MuiButton-root {
    text-transform: none;
    width: auto;
}

.txt-area textarea{
    padding:0 15px;
}

.close-btn:hover {
    cursor: pointer;
    border: 1px solid gray;
    border-radius:3px;
}
.close-icon-container {
    display: flex;
    justify-content: end;
}

.MuiFormLabel-root.MuiInputLabel-root {
    padding-left: 10px;
}

.manage-users-popup{
    height: 400px;
    overflow-x: auto;
}